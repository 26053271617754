import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HeaderLogo from "../images/pipp-header-logo.svg"
import manifestoHeaderSvg from '../images/MANIFESTO.svg'
import { Link } from "gatsby"

const ListLink = (props) => (
  <li className="header-list-element">
    <Link to={props.to} className="header-list-link">
      {props.children}
    </Link>
  </li>
);

export default () => (
  <Layout>
    <SEO title="Manifesto" />
    <main className="manifesto-main">
      <header className="nav-header">
        <nav>
          <Link className="logo-link" to="/">
            <img src={HeaderLogo} alt="Home" />
          </Link>
          <div className="heading-container">
            <ul className="header-ul">
              <ListLink to="/about">about</ListLink>
              <ListLink to="/manifesto">manifesto</ListLink>
              <ListLink to="/upcoming">upcoming</ListLink>
              <ListLink to="/past">past</ListLink>
              <ListLink to="/resources">resources</ListLink>
              <ListLink to="/contact">contact</ListLink>
            </ul>
            <img
              className="heading-img"
              src={manifestoHeaderSvg}
              alt="Manifesto"
            />
          </div>
        </nav>
      </header>
      <article>
        <p>
          Clothing patterns are the shapes of our individual bodies and our
          individual dreams. Patternmaking, then, should belong to all of us. By
          reclaiming this art and science of patternmaking, we reclaim the right
          to delineate our own selves.
        </p>
        <p>
          Everyone deserves access to this deep knowledge, regardless of age,
          race, socioeconomic circumstances, geography, language, education,
          profession, or immigration status. Intimidation, too, should not
          prevent access.
        </p>
        <p>
          Patternmaking skills must be brought out into the open, performed in
          public places, shared on street corners and displayed in parks. Then
          we -- the regular people, the folk -- can bring it back home to our
          kitchen tables, living room floors, backyards and fire escapes, where
          we can begin to outline the shapes in which we choose to live.
        </p>
      </article>
    </main>
  </Layout>
);
